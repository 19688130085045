@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@layer utilities {
  .tracking-tightest {
    letter-spacing: -0.06em;
  }

  .roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
  }

  .column-text {
    column-count: 2;
    column-gap: 2rem;
    column-fill: auto;
  }
}

@font-face {
  font-family: "Myriad Pro Regular";
  src: url("/src/fonts/myriad-pro-regular.ttf") format("truetype");
}
/* General styles */
body {
  @apply bg-main-background;
  @apply text-main-text font-myriad-pro tracking-tightest;
}

.header-component {
  @apply text-5xl mb-6 tracking-wide text-white;
  @apply uppercase border border-celine-gold;
  @apply inline-block;
  @apply p-6;
}

h1 {
  @apply font-roboto text-4xl font-bold text-celine-gold tracking-tightest mb-2;
}

h2 {
  @apply font-roboto text-3xl font-bold text-celine-gold tracking-tightest mb-2;
}

h3 {
  @apply font-roboto text-2xl font-bold text-celine-gold tracking-tightest mb-2;
}

p {
  @apply mt-6 text-lg leading-8 text-main-text;
}

a {
  @apply hover:text-celine-gold;
}

/*/ Home page Styles */

.gig-events {
  @apply flex flex-col justify-center items-center space-y-6
}


/* Card styles */
.card {
  @apply rounded overflow-hidden shadow-lg pr-5 pl-5 bg-card-background;
}

.card-image {
  @apply h-full w-full rounded-lg object-cover shadow-xl;
}

.card-content {
  @apply px-6 py-4 text-sm;
}

/* Same as h3 */
.card-title {
  @apply mt-3 font-roboto text-2xl font-bold text-celine-gold tracking-tightest;
}

/* Cover image styles */
.cover-image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.cover-image {
  width: 100%;
  height: auto;
  display: block;
}

.cover-text {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  padding: 10px;
  border-radius: 10px;
}

/* Gig styles */
.gig-description {
  @apply mb-3;
}

.gig-break {
  border-color: main-text !important;
  margin: 20px 0 !important;
}

.gig-location {
  @apply text-base text-center;
}

.gig-location-text {
  @apply inline-block text-lg;
}

.gig-time {
  @apply text-base font-bold text-center mx-auto;
}

.gig-ticket-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.gig-ticket-button {
  @apply font-bold;
}

.gigs-page {
  /* Add your styles here */
  padding: 20px;
  background-color: #f9f9f9;
}

.gigs-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

/* About us Styles */
.about-us-image-container {
  @apply w-96 h-96 object-cover shadow-xl;
}
.about-us-image {
  @apply shadow-xl rounded-lg object-cover;
}

.about-us-content .left {
  @apply pr-12;
}

.about-us-content .right {
  @apply pl-12;
}

.about-us-text {
  @apply mt-6 text-lg leading-8;
}

.about-us-col {
  column-count: 2;
  column-gap: 2rem;
}

/* Gallery Styles */

.gallery-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.gallery-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.visible {
  opacity: 1;
}

/* Mobile view: single column */
@media (max-width: 768px) {
  .gallery-container {
    flex-direction: column;
  }
}

/*Team Member Styles */

/* The styling for the team member stuff needs to be changed in TeamMember.jsx
   not sure why but when i add it here it breaks it mega style */

/* Footer Styles */
.social-media {
  @apply flex justify-center mt-8 space-x-6;
}
.social-media-icon {
  @apply w-6 h-6;
}

/* navbar Styles */

.navbar-icon {
  @apply w-6 h-6 text-white;
}

.nav-break {
  border-color: main-text !important;
  margin: 5px 0 !important;
}

/* Other styles */

.next-arrow {
  @apply absolute top-1/2 right-0 ml-3 transform -translate-y-1/2;
}

.prev-arrow {
  @apply absolute top-1/2 left-0 ml-3 transform -translate-y-1/2;
}

.slick-next:before,
.slick-prev:before {
  display: none !important;
}

.slick-dots li button:before {
  color: white !important;
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}


.select-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-dropdown select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select-dropdown::after {
  content: '▼';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}
